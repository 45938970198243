import { v4 as uuidv4 } from 'uuid';
import store from '../../config/configureStore';
import { getService } from './service';

export const assignTempIncidentId = () => {
  return uuidv4();
};

export const getWFlows = async () => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('ptswflows');
    service.timeout = 200000;
    const agencyID = store.store.getState().user.userAgency;
    const res = await service.find({
      query: {
        AgencyID: agencyID,
      },
    });
    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const getWFlowSteps = async (wflowId) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('ptswflowsteps');
    service.timeout = 200000;
    const res = await service.find({ query: { ptsWFlowID: wflowId, $sort: { Order: 1 } } });
    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const addIncIncidentWFlows = async (ptsIncidentID, ptsWFlowID, ptsWFlowStepID, Comment) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('incincidentwflows');
    service.timeout = 200000;
    const existing = await service.find({
      query: {
        ptsIncidentID,
        ptsWFlowID,
      },
    });

    if (existing.total > 0) {
      const service = client.service('rms-incident-workflow');
      service.timeout = 200000;
      return await service.update(
        {
          ptsIncidentID,
          ptsWFlowID,
        },
        { ptsWFlowStepID, IsDeleted: false }
      );
    }

    const res = await service.create({
      ptsIncidentID,
      ptsWFlowID,
      ptsWFlowStepID,
      Comment,
    });
    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const updateIncIncidentWFlows = async (ptsIncidentID, ptsWFlowID, data) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    let service = await client.service('rms-incident-workflow');
    service.timeout = 20000;

    let result = await service.update(
      {
        ptsWFlowID,
        ptsIncidentID,
      },
      data
    );

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addShares = async (ParentType, ptsParentID, ptsUserID, ShareDate, Comment) => {
  const user = store.store.getState().user;
  const service = getService('ptsshares');
  try {
    const res = await service.create({
      ParentType,
      ptsParentID,
      ptsUserID,
      ShareDate,
      Comment,
      CreatedBy: user.userData?.user.Username,
      SharedBy: user.userData?.user.ptsUserID,
      AgencyID: user.userAgency,
    });
    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const updateShares = async (ptsShareID, ptsUserID, Comment, IsDeleted) => {
  const user = store.store.getState().user;
  const service = getService('ptsshares');
  try {
    const res = await service.patch(ptsShareID, {
      ptsUserID,
      Comment,
      IsDeleted,
      UpdatedBy: user.userData?.user.Username,
      SharedBy: user.userData?.user.ptsUserID,
      AgencyID: user.userAgency,
    });

    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const getSharesPerson = async (ptsIncidentId) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('ptsshares');
    service.timeout = 200000;
    const res = await service.find({ query: { ptsParentID: ptsIncidentId } });

    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const removeSharePerson = async (ptsShareID) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('ptsshares');
    service.timeout = 20000;
    const result = await service.remove(ptsShareID);

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateStatusOfIncident = async (ptsIncidentID) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('codeincidentstatus');
    service.timeout = 200000;
    const status = await service.find({
      query: {
        Category: 'Approved',
      },
    });
    const service2 = client.service('rms-incident');
    service2.timeout = 200000;
    await service2.patch(ptsIncidentID, { status: status?.data[0].Code });
  } catch (error) {
    console.log('error ', error);
  }
};

export const checkPermission = (ptsWFlowStepID, AgencyID, userProfileID) => {
  const workflowsStepRoles = store.store.getState().workflow.workFlowStepRoles;
  const permission = workflowsStepRoles.filter(
    (item) =>
      item.ptsWFlowStepID === ptsWFlowStepID &&
      (item.permittedAgencies.includes(AgencyID) || item.permittedAgencies.includes(null)) &&
      item.permittedProfiles.includes(userProfileID)
  );

  const mergededPermission = permission.reduce(
    (processed, current) => {
      processed = {
        advance: processed.advance || current.advance,
        return: processed.return || current.return,
        remove: processed.remove || current.remove,
      };
      return processed;
    },
    {
      advance: false,
      return: false,
      remove: false,
    }
  );

  return mergededPermission;
};

export const getWorkflowsStepRoles = async (query = {}) => {
  try {
    const service = getService('rms-workflow-permission');
    const permissions = await service.find({ query: query });
    return permissions;
  } catch (error) {
    console.log('e ', error);
    return [];
  }
};
export const addRoutes = async (ParentType, ptsParentID, ptsUserID, RouteDate, Comment) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user;
  if (!client) return;
  try {
    let service = await client.service('ptsroutes');
    service.timeout = 20000;
    const res = await service.create({
      ParentType,
      ptsParentID,
      ptsUserID,
      RouteDate,
      Comment,
      CreatedBy: user.userData?.user.Username,
      RoutedBy: user.userData?.user.ptsUserID,
      AgencyID: user.userAgency,
      IsReaded: false,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
    });
    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const updateRoutes = async (ptsRouteID, ptsUserID, Comment, IsDeleted) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user;
  if (!client) return;
  const service = client.service('ptsroutes');
  service.timeout = 200000;
  try {
    const res = await service.patch(ptsRouteID, {
      ptsUserID,
      Comment,
      IsDeleted,
      UpdatedBy: user.userData?.user.Username,
      RoutedBy: user.userData?.user.ptsUserID,
      AgencyID: user.userAgency,
      IsReaded: false,
      IsLocked: false,
      IsSealed: false,
    });

    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const getRoutes = async (ptsIncidentId) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('ptsroutes');
    service.timeout = 200000;
    const res = await service.find({ query: { ptsParentID: ptsIncidentId } });

    return res;
  } catch (error) {
    console.log('error ', error);
  }
};

export const removeRoute = async (ptsRouteID) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('ptsroutes');
    service.timeout = 20000;
    const result = await service.remove(ptsRouteID);

    return result;
  } catch (error) {
    console.log(error);
  }
};
